export default {
  metaInfo: {
    title: 'Algemene voorwaarden',
  },
  name: 'Terms',
  components: {},
  props: {},
  data: () => ({
    urlPolicy: process.env.VUE_APP_URL_POLICY,
  }),
  computed: {},
  methods: {},
};
